<template>
  <div>
    <div class="client-person-proile-main-container">
      <div class="client-person-profile-container">
        <div class="client-person-profile-basic-details">
          <div class="client-person-profile-basic-details-section-1">
            <div>
              <a-avatar shape="circle" :size="100">
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
          </div>
          <div class="client-person-profile-basic-details-section-2">
            <div>
              <span class="client-person-profile-basic-details-name">
                <span v-if="clientPersonProfileData.clientPerson.title"
                  >{{ clientPersonProfileData.clientPerson.title }}.</span
                >
                <span>{{ clientPersonProfileData.clientPerson.fullName }}</span>
              </span>
              <span
                class="client-person-profile-basic-details-status-tag"
                v-if="clientPersonProfileData.clientPerson.status"
                :style="[
                  clientPersonProfileData.clientPerson.status === 'ACTIVE'
                    ? { background: 'rgba(111, 207, 151, 0.4)' }
                    : { background: 'rgba(76, 81, 85, 0.5)' },
                ]"
              >
                {{ clientPersonProfileData.clientPerson.status }}
              </span>
              <span class="client-person-profile-details-company-name"
                >Company Name :</span
              >
              <span
                class="
                  client-person-profile-details-company-name-value
                  spacer-right
                "
                >{{ clientPersonProfileData.account.name }}</span
              >
            </div>
            <div
              class="client-person-profile-details-position-department"
              style=""
            >
              <div>
                <span class="client-person-profile-details-position"
                  >Position:</span
                >
                <span class="client-person-profile-details-position-value">
                  {{ clientPersonProfileData.clientPerson.position }}
                </span>
              </div>
              <div>
                <span class="client-person-profile-details-department">
                  Department :
                </span>
                <span class="client-person-profile-details-department-vlaue">
                  {{ clientPersonProfileData.clientPerson.department }}
                </span>
              </div>
            </div>
            <div style="margin-top: 10px; display: flex">
              <div class="spacer-right">
                <span class="client-person-profile-bottom-details-2-header"
                  >Unit</span
                >
                <span class="client-person-profile-bottom-details-2-value">
                  {{ clientPersonProfileData.unit.name }}
                </span>
              </div>
              <div class="spacer-right">
                <span class="client-person-profile-bottom-details-2-header">
                  Location
                </span>
                <span class="client-person-profile-bottom-details-2-value">
                  {{ clientPersonProfileData.unit.location.country }}
                </span>
              </div>
              <div class="spacer-right">
                <span class="client-person-profile-bottom-details-2-header">
                  Last Meeting
                </span>
                <span class="client-person-profile-bottom-details-2-value">
                  {{ clientPersonProfileData.lastMeetingAt }}
                </span>
              </div>
            </div>
          </div>

          <div class="client-person-profile-basic-details-section-3">
            <div>
              <div class="client-person-profile-website">
                <span class="title">Mobile :</span>
                <span class="value">{{
                  clientPersonProfileData.clientPerson.mobile
                }}</span>
              </div>
              <div class="client-person-profile-email">
                <span class="title">Email ID :</span>
                <span class="value">{{
                  clientPersonProfileData.clientPerson.email
                }}</span>
              </div>
            </div>
            <div style="margin-top: 3rem">
              <div class="client-person-profile-dob">
                <span class="title">DOB :</span>
                <span class="value">{{
                  clientPersonProfileData.clientPerson.dob
                }}</span>
              </div>
              <div class="client-person-profile-creation-date">
                <span class="title"> Creation Date: </span>
                <span class="value">{{
                  clientPersonProfileData.clientPerson.createdAt
                }}</span>
              </div>
            </div>
          </div>
          <div class="client-person-profile-basic-details-section-4">
            <a style="color: #000000" @click="router.push('/list-companies')">
              <CloseOutlined class="account-profile-cross-icon" />
            </a>
          </div>
        </div>
        <div class="client-person-profile-details-menu">
          <a-divider class="menu-top-divider" />
          <a-menu
            v-model:selectedKeys="selectedMenuKey"
            mode="horizontal"
            class="client-person-details-menu"
          >
            <a-menu-item key="details">
              <a href="#">Details</a>
            </a-menu-item>
            <a-menu-item key="comments">
              <a href="#comments">Comments</a>
            </a-menu-item>
            <a-menu-item key="teams">
              <a href="#teams">Teams</a>
            </a-menu-item>
            <a-menu-item key="meetings">
              <a href="#meetings">Meetings</a>
            </a-menu-item>
          </a-menu>
          <a-divider class="menu-bottom-divider" />
        </div>
        <div id="details" class="client-person-middle-section">
          <div class="client-person-details">
            <h3 class="client-person-other-detail-heading">Other Details</h3>
            <div>
              <p class="client-person-other-detail-key">Birthday Bouquet</p>
              <div class="client-person-other-detail-data">
                <span> Birthday Bouquet:&nbsp;&nbsp;&nbsp;&nbsp; </span>
                <span style="color: rgb(0, 0, 0)">
                  {{ clientPersonProfileData.birthBouquet }}
                </span>
              </div>
            </div>
            <a-divider style="background-color: #b3b1b4; width: 80%" />
            <div>
              <p class="client-person-other-detail-key"><b>Newsletter</b></p>
              <div class="client-person-other-detail-data">
                <span>Newsletter:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span style="color: rgb(0, 0, 0)">{{
                  clientPersonProfileData.newsLetter
                }}</span>
              </div>
            </div>
            <a-divider style="background-color: #b3b1b4; width: 80%" />
            <div>
              <p class="client-person-other-detail-key"><b>Address</b></p>
              <div class="client-person-other-detail-data">
                <span>Address Field :</span>
                <span style="color: rgba(0, 0, 0)">{{
                  clientPersonProfileData.unit.location.type
                }}</span>
                <div class="client-person-address-main">
                  <div class="client-person-address-heading">
                    <span class="client-person-other-detail-data"
                      >Address:
                    </span>
                  </div>
                  <div class="client-person-address-body">
                    <span style="color: rgba(0, 0, 0); overflow-wrap: normal">
                      {{
                        clientPersonProfileData.unit.location.street
                      }}&nbsp;&nbsp;
                      {{
                        clientPersonProfileData.unit.location.city
                      }}&nbsp;&nbsp;
                      {{
                        clientPersonProfileData.unit.location.state
                      }}&nbsp;&nbsp;
                      {{
                        clientPersonProfileData.unit.location.country
                      }}&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a-divider type="vertical" class="middle-section-vertical-divider" />
          <div class="client-person-comments">
            <div class="client-person-vito-comments-section" id="comments">
              <div class="header">
                <span class="title">
                  <span class="title-count">{{ notesCount }}</span> Native
                  Comments</span
                >
              </div>
              <div class="main-section">
                <a-card
                  :bordered="false"
                  v-for="note in notesData"
                  :key="note.id"
                >
                  <div class="comments-top-section">
                    <div>
                      <a-avatar shape="circle" :size="50">
                        <template #icon><UserOutlined /></template>
                      </a-avatar>
                    </div>
                    <div class="commentators-details">
                      <p class="name">{{ note.name }}</p>
                      <span class="commentators-details-group"></span>
                      <p class="position"></p>
                    </div>
                  </div>
                  <p class="comment">{{ note.title }}.</p>
                </a-card>
              </div>
              <a-input
                v-model:value="comments"
                placeholder="Type in Your Comments"
                class="comments-input"
              />
            </div>
          </div>
        </div>

        <a-divider id="teams" style="background-color: rgb(0, 0, 0)" />
        <div class="client-person-profile-tables">
          <div class="team-cic-table-section">
            <div class="top-sub-section">
              <span class="number">{{ teamsCount }}</span>
              <span class="text"> Sub Teams</span>
              <span class="number right-text">{{ cicCount }}</span>
              <span class="text"> CIC</span>
            </div>
            <div class="subTeamsTable">
              <a-table
                :row-selection="teams_rowSelection"
                :columns="subTeamsColumns"
                :data-source="teams_data"
                :pagination="false"
                :rowKey="(record) => record.id"
              />
              <div class="listing-table-view-more-wrapper">
                <div v-if="teams_data.length < fetchedTeamsData.length">
                  <span
                    @click="viewMoreTeams()"
                    class="meeting-list-view-more-text"
                    ><PlusCircleOutlined /> View more</span
                  >
                </div>
              </div>
            </div>
            <div class="cicTable">
              <a-table
                :row-selection="cicsRowSelection"
                :columns="cicColumns"
                :data-source="cic_data"
                :pagination="false"
                :rowKey="(record) => record.id"
              />
              <div class="listing-table-view-more-wrapper">
                <div v-if="cic_data.length < fetchedCicData.length">
                  <span
                    @click="viewMoreCics()"
                    class="meeting-list-view-more-text"
                    ><PlusCircleOutlined /> View more</span
                  >
                </div>
              </div>
            </div>
          </div>
          <a-divider  id="meetings" style="background-color: rgb(0, 0, 0); margin-top: 0" />
          <div class="meeting-table-section">
            <div class="top-sub-section">
              <span class="number">{{ meetingsCount }}</span>
              <span class="text"> Meetings</span>
            </div>
            <div class="meetingTable">
              <a-table
                :row-selection="meeting_rowSelection"
                :columns="meeting_columns"
                :data-source="meeting_data"
                :pagination="false"
                :rowKey="(record) => record.id"
              />
              <div
                class="listing-table-view-more-wrapper"
                v-if="meeting_data.length < fetchedMeetingData.length"
              >
                <div @click="viewMoreMeetings()">
                  <PlusCircleOutlined />
                  <span class="meeting-list-view-more-text">View more</span>
                </div>
              </div>
            </div>
          </div>
          <a-divider style="background-color: black" />
        </div>
      </div>
      <div class="right-section">
        <ActionBar />
      </div>
    </div>
  </div>
</template>
<script>
const selectedMenuKey = ref(["vacancies"]);
var shiftWindow = function () {
  scrollBy(0, -250);
  console.log(screen);
};
if (selectedMenuKey.value == "teams") {
  console.log("teaammmmmmmmmm");
}
import { reactive, ref, onMounted } from "vue";
import {
  UserOutlined,
  CloseOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons-vue";
import ActionBar from "./rightNav.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import Services from "@/services/apis";
import Utils from "../../../utils";
const subTeamsColumns = [
  {
    title: "Sub Team",
    dataIndex: "subTeam",
    width: 480,
  },
  {
    title: "RAG Status",
    dataIndex: "rag_status",
  },
  {
    title: "Last Meeting",
    dataIndex: "lastMeetingAt",
  },
];
const cicColumns = [
  {
    title: "CIC",
    dataIndex: "cic",
    width: 350,
  },
  {
    title: "Priority",
    dataIndex: "priority",
  },
  {
    title: "RAG Status",
    dataIndex: "rag_status",
  },
  {
    title: "Last Meeting",
    dataIndex: "lastMeetingAt",
  },
];
const meeting_columns = [
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Department",
    dataIndex: "department",
  },
  {
    title: "Lead CIC",
    dataIndex: "lead_cic",
  },
  {
    title: "Attended CIC",
    dataIndex: "attended_cic",
  },
  {
    title: "Tracker Type",
    dataIndex: "tracker_type",
  },
  {
    title: "Team",
    dataIndex: "team",
  },
  {
    title: "Sub Team",
    dataIndex: "sub_team",
  },
];

const teams_data = reactive([]);
const fetchedTeamsData = reactive([]);
const teamsCount = ref("00");
const cicCount = ref("00");
const meeting_data = reactive([]);
const fetchedMeetingData = reactive([]);
const cic_data = reactive([]);
const fetchedCicData = reactive([]);
const notesData = reactive([]);
const notesCount = ref("00");
const meetingsCount = ref("00");
let teamListPageCount = 3;
let cicListPageCount = 3;
let meetingListPageCount = 3;

let clientPersonProfileData = reactive({
  clientPerson: {
    id: "",
    clientPersonId: "",
    fullName: "",
    mobile: "",
    email: "",
    dob: "",
    position: "",
    department: "",
    createdAt: "",
    status: "",
    title: "",
  },
  address: {},
  unit: {
    id: "",
    name: "",
    location: "",
  },
  account: {
    id: "",
    name: "",
  },
  subTeams: [
    {
      id: "",
      name: "",
      rag: {
        id: "",
        name: "",
        color: "",
      },
      type: "",
      cic: {
        id: "",
        name: "",
      },
      priority: "",
      lastMeetingAt: "",
    },
  ],
  birthBouquet: "",
  newsLetter: "",
  lastMeetingAt: "",
});
export default {
  components: {
    UserOutlined,
    CloseOutlined,
    ActionBar,
    PlusCircleOutlined,
  },
  setup() {
    if (location.hash) shiftWindow();
    window.addEventListener("hashchange", shiftWindow);
    const {
      params: { id },
    } = useRoute();
    const selectedMenuKey = ref(["details"]);
    let router = useRouter();
    const cicsRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };
    const teams_rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };
    const meeting_rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          "selectedRows: ",
          selectedRows
        );
      },
    };

    const getClientPersonProfileData = (id) => {
      let tempObject;
      Services.getClientPersonDetails(id).then((response) => {
        if (response.status === 200) {
          const { data } = response;
          tempObject = {
            clientPerson: data.clientPerson
              ? {
                  id: "",
                  clientPersonId: data.clientPerson.clientPersonId
                    ? data.clientPerson.clientPersonId
                    : "-",
                  fullName: data.clientPerson.fullName
                    ? data.clientPerson.fullName
                    : "-",
                  mobile: data.clientPerson.mobile
                    ? data.clientPerson.mobile
                    : "-",
                  email: data.clientPerson.email
                    ? data.clientPerson.email
                    : "-",
                  dob: data.clientPerson.dob
                    ? Utils.displayLongDate(data.clientPerson.dob)
                    : "-",
                  position: data.clientPerson.position
                    ? data.clientPerson.position
                    : "-",
                  department: data.clientPerson.department
                    ? data.clientPerson.department
                    : "-",
                  createdAt: data.clientPerson.createdAt
                    ? Utils.displayLongDate(data.clientPerson.createdAt)
                    : "-",
                  status: data.clientPerson.status
                    ? data.clientPerson.status
                    : "-",
                  title: data.clientPerson.title ? data.clientPerson.title : "",
                }
              : {},
            address: {},
            unit: {
              id: data.unit ? data.unit.id : "-",
              name: data.unit ? data.unit.name : "-",
              location:
                data.unit && data.unit.location
                  ? {
                      city: data.unit.location.city
                        ? data.unit.location.city
                        : "",
                      country: data.unit.location.country
                        ? data.unit.location.country
                        : "",
                      state: data.unit.location.state
                        ? data.unit.location.state
                        : "",
                      street: data.unit.location.street
                        ? data.unit.location.street
                        : "",
                      type: data.unit.location.type
                        ? data.unit.location.type
                        : "",
                      zip: data.unit.location.zip
                        ? data.unit.location.zip
                        : "",
                    }
                  : {
                      city: "",
                      country: "",
                      state: "",
                      street: "",
                      type: "",
                      zip: "",
                    },
            },
            account: {
              id: data.account.id ? data.account.id : "-",
              name: data.account.name ? data.account.name : "-",
              companyId: data.account.companyId ? data.account.companyId : "-",
            },
            cic: data.cic ? data.cic : [],
            subTeams: data.subTeams.length ? data.subTeams : [],
            birthBouquet: data.birthBouquet ? "Yes" : "No",
            newsLetter: data.newsLetter ? "Yes" : "No",
            lastMeetingAt: data.lastMeetingAt
              ? Utils.displayLongDate(data.lastMeetingAt)
              : "-",
          };
        }
        getSubTeamData(tempObject.subTeams, 3);
        getCicData(tempObject.cic, 3);
        Object.assign(clientPersonProfileData, tempObject);
      });
    };
    const getClientPersonMeetings = (id) => {
      let tempData = [];
      Services.getClientPersonMeetings(id)
        .then((response) => {
          if (response.status === 200) {
            let { data } = response;
            data &&
              data.count &&
              data.meetings.map((meeting) => {
                tempData.push({
                  id: meeting.id,
                  date: meeting.date
                    ? Utils.displayLongDate(meeting.date)
                    : "-",
                  department: Object.keys(meeting.department).length
                    ? meeting.department.name
                    : "-",
                  lead_cic: meeting.leadCics.length
                    ? meeting.leadCics.map((x) => x.name)
                    : "-",
                  attended_cic: meeting.attendedCics.length
                    ? meeting.attendedCics.map((x) => x.name)
                    : "-",
                  tracker_type: meeting.type,
                  team: meeting.teams.length
                    ? meeting.teams.map((x) => x.name)
                    : "-",
                  sub_team: meeting.subTeams.length
                    ? meeting.subTeams.map((x) => x.name)
                    : "-",
                });
              });
            if (data.count > 0) {
              meetingsCount.value =
                data.count < 10 ? "0" + data.count : data.count;
            }
            Object.assign(fetchedMeetingData, tempData);
            Object.assign(
              meeting_data,
              fetchedMeetingData.slice(0, meetingListPageCount)
            );
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const viewMoreMeetings = () => {
      meetingListPageCount += 3;
      Object.assign(
        meeting_data,
        fetchedMeetingData.slice(0, meetingListPageCount)
      );
    };

    const getClientPersonNotes = (id) => {
      let tempData = [];
      Services.getClientPersonNotes(id).then((response) => {
        if (response.status === 200) {
          const { data } = response;
          data &&
            data.count &&
            data.notes.map((note) => {
              tempData.push({
                id: note.id,
                name: note.createdBy ? note.createdBy.name : "-",
                title: note.title ? note.title : "-",
                description: note.description ? note.description : "-",
                createdAt: note.createdAt
                  ? Utils.displayLongDate(note.createdAt)
                  : "-",
              });
            });
          if (data.count > 0) {
            notesCount.value = data.count < 10 ? "0" + data.count : data.count;
          }
          Object.assign(notesData, tempData);
        }
      });
    };
    const getSubTeamData = (subTeams, teamListPageCount) => {
      let tempData = [];
      fetchedTeamsData.splice(0);
      teams_data.splice(0);
      teamsCount.value = "00";
      if (subTeams.length > 0) {
        tempData = subTeams.map((team) => {
          let temp = {
            id: id,
            subTeam: team.name ? team.name : "-",
            rag_status: team.rag && team.rag.id ? team.rag.name : "-",
            lastMeetingAt: team.lastMeetingAt
              ? Utils.displayLongDate(team.lastMeetingAt)
              : "-",
          };

          return temp;
        });
      }
      if (tempData.length > 0) {
        teamsCount.value =
          tempData.length < 10 ? "0" + tempData.length : tempData.length;
      }
      Object.assign(fetchedTeamsData, tempData);
      Object.assign(teams_data, tempData.slice(0, teamListPageCount));
    };

    const getCicData = (cics, cicListPageCount) => {
      let tempData = [];
      fetchedCicData.splice(0);
      cic_data.splice(0);
      cicCount.value = "00";
      if (cics[0].id) {
        tempData = cics.map((cic) => {
          let temp = {
            id: cic.id,
            cic: cic.name ? cic.name : "-",
            priority: cic.priority ? cic.priority : "-",
            rag_status: cic.rag && cic.rag.id ? cic.rag.name : "-",
            lastMeetingAt: cic.lastMeetingAt
              ? Utils.displayLongDate(cic.lastMeetingAt)
              : "-",
          };
          return temp;
        });
      }
      if (tempData.length > 0) {
        cicCount.value =
          tempData.length < 10 ? "0" + tempData.length : tempData.length;
      }
      Object.assign(fetchedCicData, tempData);
      Object.assign(cic_data, tempData.slice(0, cicListPageCount));
    };

    const viewMoreTeams = () => {
      teamListPageCount += 3;
      Object.assign(teams_data, fetchedTeamsData.slice(0, teamListPageCount));
    };
    const viewMoreCics = () => {
      cicListPageCount += 3;
      Object.assign(teams_data, fetchedTeamsData.slice(0, cicListPageCount));
    };

    onMounted(() => {
      getClientPersonProfileData(id);
      getClientPersonMeetings(id);
      getClientPersonNotes(id);
    });

    return {
      selectedMenuKey,
      meeting_rowSelection,
      teams_rowSelection,
      cicsRowSelection,
      meeting_columns,
      router,
      clientPersonProfileData,
      getClientPersonMeetings,
      getClientPersonNotes,
      teams_data,
      cic_data,
      fetchedTeamsData,
      fetchedCicData,
      teamsCount,
      cicCount,
      notesData,
      notesCount,
      meeting_data,
      fetchedMeetingData,
      meetingsCount,
      subTeamsColumns,
      cicColumns,
      viewMoreTeams,
      viewMoreCics,
      viewMoreMeetings,
      teamListPageCount,
      cicListPageCount,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/clientPersonProfile.scss";
</style>
